<template>
  <div>
    <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>内容管理</el-breadcrumb-item>
      <el-breadcrumb-item>医生科普</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="add">
      <el-button @click="toDetail('new', '', '6')" size="small">添加</el-button>
    </div>
    <el-table :data="list" border style="width: 100%">
      <el-table-column label="编号" prop="id" width="80"></el-table-column>
      <el-table-column prop="id" v-if="false"></el-table-column>
      <el-table-column prop="title" label="标题"></el-table-column>
      <el-table-column>
        <template slot-scope="scope">
          <div class="opt">
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-edit"
              @click="toDetail('bj', scope.row, '6')"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="del(scope.row, scope.$index)"
              >删除</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <p class="paging">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="pageSizes"
        :page-size="PageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount"
        :hide-on-single-page="value"
      ></el-pagination>
    </p>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [],
      current: 1, //默认显示第几页
      totalCount: 0, // 总条数，根据接口获取数据长度
      pageSizes: [20], // 个数选择器（可修改）
      PageSize: 20, // 默认每页显示的条数（可修改）
      value: false,
    };
  },
  mounted() {
    this.getdata(1, this.PageSize);
  },
  methods: {
    toDetail(type, item, cate) {
      if (type == "new") {
        this.$router.push({
          path: "/articleManage/articleDetail",
          query: { type: type, cate: cate },
        });
      } else {
        this.$router.push({
          path: "/articleManage/articleDetail",
          query: { type: type, id: item.id, cate: cate },
        });
      }
    },
    handleSizeChange() {},
    handleCurrentChange(currentPage) {
      this.getdata(currentPage, this.PageSize);
    },
    getdata(page, size) {
      this.axios
        .get("/gu/get_gu_articles?page=" + page + "&size=" + size + "&cate=6")
        .then((rs) => {
          if (rs.data.code == 0) {
            this.list = rs.data.result;
            this.totalCount = rs.data.count;
          } else {
            console.log(rs);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    del(row, index) {
      //删除
      this.$confirm("此操作将永久删除该文章, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.axios
            .post("/gu/delete_gu_article", this.qs.stringify({ id: row.id }))
            .then((rs) => {
              if (rs.data.code == 0) {
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
                this.list.splice(index, 1);
              } else {
                this.$message(rs.data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>
<style>
.opt a {
  cursor: pointer;
  color: green;
  margin-right: 20px;
  display: inline-block;
}

.add {
  width: 100%;
  height: 50px;
  text-align: left;
}
.Bdh {
  height: 50px;
  width: 100%;
  line-height: 40px;
}
</style>